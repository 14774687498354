import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import useMedia from 'use-media';
import abi from './utils/nfab.json';
import { ethers } from "ethers";

function App() {

  const [address, setAddress] = useState('');
  const [mintQuantity, setMintQuantity] = useState();
  const [errMsg, setErrMsg] = useState('');
  const contractAddress = '0x69534B0E3cE1300d03D1D60E1C83c3C8e2D14763';
  const contractAbi = abi.abi;

  const connectWallet = async () => {
     /* Connect wallet */
     try {
      const { ethereum } = window;

      if (!ethereum) {
        return;
      }

      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

      if (accounts.length !== 0) {
        const address = accounts[0];
        setAddress(address);
      }
    } catch(error) {
      console.log(error);
    }
  }

  const mint = async () => {
    const { ethereum } = window;

    if (!address || address === '') {
      setErrMsg('You must connect your wallet');
      return;
    }

    if (!mintQuantity) {
      setErrMsg('You must enter a quantity');
      return;
    }
 
     if (ethereum) {
       const provider = new ethers.providers.Web3Provider(ethereum);
       const signer = provider.getSigner();
       const contract = new ethers.Contract(contractAddress, contractAbi, signer);
 
       if (contract) {
        try { 
          await contract.mint(parseInt(mintQuantity), {from: address, value: ethers.utils.parseEther((0.01 * mintQuantity).toString())});
        } catch(err) {
          console.log(err);
        }
       }
     } else {
       console.log('Ethereum obejct doesnt not exist');
     }
   }

  const tablet = useMedia({minWidth: '500px'});
  const desktop = useMedia({minWidth: '850px'});
  const mobile = useMedia({minWidth: '200px'})

  console.log('mintQuantity: ', mintQuantity)

  useEffect(() => {
    if (mintQuantity && mintQuantity !== '' && mintQuantity !== null) {
      setErrMsg('');
    }

    if (address && address !== '') {
      setErrMsg('');
    }
  }, [mintQuantity, address])

  return (
    <div className='App' style={{background: '#202020', height: '100%', overflow: 'none'}}>
      {address !== '' ? <span style={{color: 'white', position: 'absolute', right: '1rem', top: '3rem', fontSize: '14px'}}>Connected: {address.toUpperCase().slice(0, 5)}...{address.toUpperCase().slice(38, 42)}</span> : <Button style={{backgroundColor: '#a91723', color: 'white', fontSize: '1rem', padding: '.25rem', position: 'absolute', right: '1rem', top: '2rem', paddingLeft: '.75rem', paddingRight: '.75rem'}} onClick={connectWallet}>Connect Wallet</Button>}
      <div className='top-container' style={{width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
        <div  style={{margin: '0 auto', width: '100%', display: 'flex', justifyContent: 'center'}}>
          <img src='/images/logo.png' style={{width: '20rem', paddingTop: '4rem', paddingBottom: '2rem'}}></img>
        </div>
      </div>
      <div className='applebees-text' style={{fontSize: '2.2rem', textAlign: 'center', fontWeight: 'bold', display: 'flex', justifyContent: 'center', overflowX: 'hidden'}}>
        {desktop && 
        <>
        <div style={{textAlign: 'center', width: '100%', display: 'flex'}}>
          <span style={{color: 'white', paddingLeft: '1rem'}}>THE UPRISING IS HERE.</span>
          <span style={{color: '#a91723', paddingLeft: '1rem'}}>THE UPRISING IS HERE</span>
        </div>
        <div style={{textAlign: 'center', width: '100%', display: 'flex'}}>
          <span style={{color: 'white', paddingLeft: '1rem'}}>THE UPRISING IS HERE.</span>
          <span style={{color: '#a91723', paddingLeft: '1rem'}}>THE UPRISING IS HERE</span>
        </div>
        <div style={{textAlign: 'center', width: '100%', display: 'flex'}}>
          <span style={{color: 'white', paddingLeft: '1rem'}}>THE UPRISING IS HERE.</span>
          <span style={{color: '#a91723', paddingLeft: '1rem'}}>THE UPRISING IS HERE</span>
        </div>
        </>
        }
        {tablet && !desktop &&
          <><div style={{textAlign: 'center', width: '100%', display: 'flex'}}>
          <span style={{color: 'white', paddingLeft: '1rem'}}>THE UPRISING IS HERE.</span>
          <span style={{color: '#a91723', paddingLeft: '1rem'}}>THE UPRISING IS HERE</span>
        </div>
        <div style={{textAlign: 'center', width: '100%', display: 'flex'}}>
          <span style={{color: 'white', paddingLeft: '1rem'}}>THE UPRISING IS HERE.</span>
          <span style={{color: '#a91723', paddingLeft: '1rem'}}>THE UPRISING IS HERE</span>
        </div>
        </>
        }
        {mobile && !tablet && !desktop &&
          <><div style={{textAlign: 'center', width: '100%', display: 'flex'}}>
          <span style={{color: 'white', paddingLeft: '1rem'}}>THE UPRISING IS HERE.</span>
          <span style={{color: '#a91723', paddingLeft: '1rem'}}>THE UPRISING IS HERE</span>
        </div>
        </>
        }
      </div>
      <div style={{display: 'flex', justifyContent: 'center', paddingTop: '5rem', textAlign: 'center', alignItems: 'center'}}>
      <p style={{color: 'white'}}>Quantity: </p>
      <input style={{width: '100px', height: '20px', marginLeft: 10}} onChange={(event) => setMintQuantity(event.target.value)}></input>
      <br></br><br></br>
      </div>
      <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
      <p style={{color: 'white', fontSize: '12px', position: 'relative', bottom: 8}}>Enter the number of Applebee's you'd like to mint (1578 franchises total)</p>
      </div>
 <div style={{display: 'flex', justifyContent: 'center', paddingTop: 10, paddingBottom: 20}}>
        <Button style={{backgroundColor: '#a91723', color: 'white', fontSize: '1.3rem', padding: '1rem', opacity: ((!mintQuantity || mintQuantity === '' || mintQuantity === null) ? '0.3': 1)}} onClick={mint}>Mint an Applebee's (.01 ETH)</Button>
      </div>
      <div style={{textAlign: 'center', position: 'relative', bottom: 12}}>
      <p style={{color: 'red', fontSize: 14}}>{errMsg}</p>
      </div>
      <div style={{color: 'white', display: 'flex', width: '85%', alignItems: 'center', alignContent: 'center', margin: '0 auto', fontSize: '1.5rem', textAlign: 'center', lineHeight: '2.5rem'}}>
        <p>
          <p>A spectre is haunting web2—<span style={{color: '#a91723'}}>the spectre of web3.</span> The powers of the old web have entered into a Holy Alliance to exorcise this spectre: Gensler and Meta, Warren and Yellen, the Fed, and most insidious of all, OpenSea. Our efforts today concern the latter: <span style={{fontWeight: 'bold', fontSize: '1.5rem', color: '#a91723'}}>A beacon has been lit and Non Fungible Applebee's has formed to answer its call.</span></p><br></br>

          <p>On Thursday, December 29th, <span style={{color: '#a91723'}}>OpenSea delisted the Non Fungible Olive Garden (NFOG) NFT collection from its website with no prior warning or explanation. </span>NFOG represented a community effort to resist “the capricious whims of the fiat system” that holds Olive Garden franchises captive to this very day. After amassing a dedicated cohort of <span style={{color: '#a91723'}}>880 franchise NFT holders and 1500 $BSTICK token holders,</span> it became clear that a new path had been charted for all—a path away from the constraints of legacy dining systems and physical real estate to the permission-less expanse that is Ethereum's chain of blocks. </p><br></br>

          <p>But just as hopes soared to new heights, the centralized powers that be swept in to butcher this movement in one Opensea-led blow. At the hands of what was deemed a one-shot-kill, NFOG suffered deep wounds: <span style={{color: '#a91723'}}>Opensea’s ban isolated them from a key entry point for new community members and sowed seeds of doubt & confusion in existing ones.</span></p><br></br>
          
          <p>But alas, quiet as the nights since may have been, a growing resistance survives. <span style={{color: '#a91723'}}>And in a show of support for NFOG, on February 22nd Non Fungible Applebee’s (NFAB) is enabling anyone to mint a nonfungible token representing 1 of 1578 Applebee franchises in the United States and is directing 10% of the proceeds to the $BSTICK liquidity pool.</span></p><br></br>

          <p>NFAB represents only the first of a coordinated series of NFT launches designed to force Opensea’s hand and incentivize hodling $BSTICK. We plan to flood OpenSea with more of the so-called “IP” that they’ve been so quick to ban, and in doing so, source new liquidity for $BSTICK.</p>
          
          <br></br>
          <span style={{color: '#a91723'}}>
          <p style={{fontWeight: 'bold'}}>We envision this will accomplish 3 things:</p>
          <p style={{fontWeight: 'bold'}}>1. send a message that bottoms-up IP remixing is here to stay and centralized platforms can’t expect to quietly smother it and just walk away</p>
          <p style={{fontWeight: 'bold'}}>2. chart a path for non-NFOG members to join the movement, earn skin in the game, and thereby, help to amplify this message</p>
          <p style={{fontWeight: 'bold'}}>3. incentivize Opensea to engage in an open dialogue with its community and clarify its stance on the above matters</p>
          <br></br>
          </span>

          <p>To be clear, we consider OpenSea to be frens. We acknowledge the scrutiny they face and are encouraged by their recent acceptance of the Dharma team + ethos. That said, we know they can do better and expect a direct response.</p>
        </p>
        <br></br>
      </div>
      <div style={{color: 'white', height: '100%', textAlign: 'center', width: '85%', alignItems: 'center', alignContent: 'center', margin: '0 auto',  paddingTop: '3rem', paddingBottom: '3rem'}}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <img src='/images/apple.png' style={{width: '7rem', height: '7rem'}}></img>
          <p style={{fontSize: '2.3rem', fontWeight: 'bold'}}>FAQs</p>
          <img src='/images/apple.png' style={{width: '7rem', height: '7rem'}}></img>
        </div>
      <p style={{fontWeight: 'bold', color: '#a81522', fontSize: '1.3rem'}}>Does this confer ownership of or investment in a real Applebee's franchise?</p>

      <p>While every Non-fungible Applebee's is tethered to a real Applebee's, ownership is currently limited to the Non-fungible Applebee's Metaverse, granting owners no rights or privileges in meatspace Applebee's. This may change in the future (see roadmap).</p>

      <p style={{fontWeight: 'bold', color: '#a81522', fontSize: '1.3rem'}}>Is this affiliated with Applebee's?</p>

      <p>No. We are simply a community of Applebee's and Olive Garden fans invested in both trustless future economies and delicious, reasonably-priced Italian fare.</p>

      <p style={{fontWeight: 'bold', color: '#a81522', fontSize: '1.3rem'}}>So am I buying a picture of an Applebee's?</p>

      <p>No. Token artwork is for representation only and confers no ownership over the photograph. You're not purchasing art, you're purchasing ownership of a Non-Fungible Applebee's franchise.</p>

      <p style={{fontWeight: 'bold', color: '#a81522', fontSize: '1.3rem'}}>One of the photos is wrong or out of date.</p>

      <p>We've done our best to verify the accuracy of the images used, but there may be discrepancies.</p>

      <p style={{fontWeight: 'bold', color: '#a81522', fontSize: '1.3rem'}}>Can I buy a specific Applebee's?</p>

      <p>The initial mint of franchises is random and you're unable to select a location. You can browse franchises that have already been minted on OpenSea and make offers there.</p>

      <p style={{fontWeight: 'bold', color: '#a81522', fontSize: '1.3rem'}}>How much does it cost to mint a Non-Fungible Applebee's NFT?</p>

      <span>It costs .01 ETH to mint a Non-Fungible Applebee's NFT.</span> <br></br>
      </div>
    </div>
  );
}

export default App;
